import React from "react";
import Slider from "react-slick";
import ava01 from "../../assets/images/ava-1.jpg";
import ava02 from "../../assets/images/ava-2.jpg";
import ava03 from "../../assets/images/ava-3.jpg";
import ava04 from "../../assets/images/sam.jpg";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p>
        Metagig Gen AI training programs helped me understand various aspects of generative ai to build LLM apps. After the course completion, I got a job as a Gen AI Lead with a salary hike of 53%.
        </p>

        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava01} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Joseph Crown</h6>
            <p></p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
        My learning experience was outstanding. My favorite feature about the course is the blended learning approach, 
        where one can engage in live classes with the convenience of learning at his own pace.
        </p>

        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava02} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Queen Franklin</h6>
            <p></p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
        The instructors were industry experts.The course curriculum is very well designed and delivered. Starting with lectures,
        Knowledge checks and my favorite part - labs/activities with your peers.
        </p>

        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava03} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Jerry Donald</h6>
            <p></p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p>
        Thank you for explaining the fundamentals so well. I will definitely purchase your 
        Azure AI Associate courses as I am looking to gain an advanced Microsoft certificate.
        </p>

        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava04} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Sam Deji</h6>
            {/* <p>Customer</p> */}
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonials;
