const questions = [
  {
    id: 1,
    title: 'AI for Beginners',
    info:
      'History about AI, Fundamentals of Machine Learning, and The Process of Machine Learning.',
  },// ALL - https://cloud.google.com/blog/topics/training-certifications/new-google-cloud-generative-ai-training-resources
  {
    id: 2,
    title: 'Intro to Generative AI',
    info:
      'What is Generative AI, What are Generative AI models and the A, B, Cs of AI.',
  },
  {
    id: 3,
    title: 'Intro to Large Language Models',
    info:
      'What are large language models (LLMs), Use Cases & How LLMs Work, Prompt tuning & Google tools for Gen AI apps.',
  },
  {
    id: 4,
    title: "Google's Gen AI Tools & Responsible AI",
    info:
      'Why Google and AI principles, The need for a responsible AI practice and Impact on responsible AI.',
  },
  {
    id: 5,
    title: 'Google Gen AI Fundamentals Certification Prep',
    info:
      'Upon finishing the above required courses, you will practice to prepare for Google\'s Online Gen AI Fundamentals Assessment.',
  },// 1 to 5 https://www.cloudskillsboost.google/paths/118
  {
    id: 6,
    title: "Google's Generative AI for Developers",
    info:
      'Intro to Google\'s Gen AI Studio, Get started with Gen AI Studio and Prototype & customize generative AI models.',
  },
  {
    id: 7,
    title: 'Generative AI Explorer - Vertex AI',
    info:
      'Getting started with Vertex AI, How to use the models in Vertex AI and Prompt Design.',
  },
  {
    id: 8,
    title: 'Prompt Design using PaLM',
    info:
      'Design good quality prompts, Interact with PaLM to get the desired responses & Recognize hallucinations in responses.',
  },
  {
    id: 9,
    title: 'Explore and Evaluate Models using Model Garden',
    info:
      'Intro to Model Garden, Model Garden on Vertex AI and Types of Vertex AI Model in the Model Garden',
  },//6 to 9 https://www.cloudskillsboost.google/paths/183
  {
    id: 10,
    title: "Vertex AI Text-Embeddings API",
    info:
      'This hands-on project include tasks like classification, outlier detection, text clustering and semantic search.',
  },//->Ng
  {
    id: 11,
    title: "Next Steps: Professional Google Exams",
    info:
      'An overview of how to prepare for the Google Cloud Professional Machine Learning Engineer Bootcamp & certification exam.',
  },
] // NEXT ML Learning path https://www.cloudskillsboost.google/paths/17/ https://www.coursera.org/professional-certificates/preparing-for-google-cloud-machine-learning-engineer-professional-certificate
export default questions
