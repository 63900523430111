const mquestions = [

        {
          "id": 1,
          "title": "Machine Learning & AI Concepts",
          "info": "Azure AI Exam Guide Breakdown,\nLayers of Machine Learning,\nKey Elements of AI,\nDataSets,\nLabeling,\nSupervised and Unsupervised Reinforcement,\nNetural Networks and Deep Learning,\nGPU,\nCUDA,\nSimple ML Pipeline,\nForecast vs Prediction"
        },
        {
          "id": 2,
          "title": "Performance Metrics",
          "info": "Metrics,\nJupyter Notebooks,\nRegression,\nClassification,\nClustering,\nConfusion Matrix,\nLabs 0.0.0"
        },
        {
          "id": 3,
          "title": "Common AI Workloads",
          "info": "Anomaly Detection AI,\nComputer Vision AI,\nNatural Language Processing AI,\nConversational AI,\nLabs 1.0.0"
        },
        {
          "id": 4,
          "title": "Responsible AI",
          "info": "Fairness,\nReliability and safety,\nPrivacy and security,\nInclusiveness,\nTransparency,\nAccountability,\nGuidelines for Human AI Interaction,\nFollow Along Guidelines for Human AI Interaction,\nLabs 1.0.1"
        },
        {
          "id": 5,
          "title": "Cognitive Services",
          "info": "Azure Cognitive Services,\nCognitive API Key and Endpoint,\nKnowledge Mining,\nFace Service,\nSpeech and Translate Service,\nText Analytics,\nOCR Computer Vision,\nForm Recognizer,\nForm Recognizer Custom Models,\nForm Recognizer Prebuilt Models,\nLUIS,\nQnA Maker,\nAzure Bot Service"
        },
        {
          "id": 6,
          "title": "ML Studio",
          "info": "Azure Machine Learning Service,\nStudio Overview,\nStudio Compute,\nStudio Data Labeling,\nData Stores,\nDatasets,\nExperiments,\nPipelines,\nML Designer,\nModel Registry,\nEndpoints,\nNotebooks,\nLabs 1.0.2"
        },
        {
          "id": 7,
          "title": "AutoML",
          "info": "Introduction to AutoML,\nData Guard Rails,\nAutomatic Featurization,\nModel Selection,\nExplanation,\nPrimary Metrics,\nValidation Type,\nLabs 1.0.3"
        },
        {
          "id": 8,
          "title": "Custom Vision",
          "info": "Introduction to Custom Vision,\nProject Types and Domains,\nCustom Vision Features,\nLabs 1.0.4"
        },
        {
          "id": 9,
          "title": "Follow Along Sessions",
          "info": "Setup,\nComputer Vision,\nCustom Vision Classification,\nCustom Vision Object Detection,\nFace Service,\nForm Recognizer,\nOCR"
        },
            {
          "id": 10,
          "title": "Follow Along Sessions 2",
          "info": "Text Analysis,\nQnAMaker,\nLUIS,\nAutoML,\nDesigner,\nMNIST,\nData Labeling,\nClean up"
        },
        {
          "id": 11,
          "title": "Practice Session - Azure AI-900 Certification Exams",
          "info":"Instructor led exam practice session amongst learners online."
        },
        {
          "id": 12,
          "title": "Next Steps: Professional Microsoft Azure Exams",
          "info":"An overview of how to prepare for the Azure AI Engineer Associate Bootcamp & Exams."
        }      
      
]

export default mquestions