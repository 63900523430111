import React, {useState, useEffect} from "react";
import "../styles/home.css";

import { Container, Row, Col } from "reactstrap";
//import heroImg from "../assets/images/hero-img01.jpg";
//import heroImg02 from "../assets/images/hero-img02.jpg";
import heroVideo from "../assets/images/hero-video.mp4";
import worldImg from "../assets/images/world.png";
import experienceImg from "../assets/images/experience.png";
import Subtitle from "./../shared/Subtitle";
import SearchBar from "../shared/SearchBar";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";
import SingleQuestion from '../components/Accordion/Question'
import data from '../components/Accordion/data'
import mdata from '../components/Accordion/mdata'
import pdata from '../components/Accordion/pdata'
import ReactGa from 'react-ga'
import './home.css'

const Home = () => {
  // eslint-disable-next-line no-unused-vars
  useEffect(()=>{
    ReactGa.pageview(window.location.pathname);
  }, [])
  const [questions, setQuestions] = useState(data);
  const [mquestions, setMquestions] = useState(mdata);
  const [pquestions, setPquestions] = useState(pdata);
  return (
    <>
      {/* ========== hero section start =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center ">
                  <Subtitle subtitle={"Become a Generative Ai Developer Without Debt"} />
                  <img src={worldImg} alt="" />
                </div>
                <h1>
                Accelerate your career with Generative Ai Bootcamps <br/>
                  <span className="highlight"> Next Cohort April 2024 </span>
                </h1>
                <p>
                  Online bootcamps are hosted by experienced tech engineers, which include industry-relevant courses such as 
                  AI fundamentals, Microsoft Azure AI and Google Gen AI certification, Chat GPT and 
                  LLM hands-on activities for your portfolio website and office hour sessions. 
                  Get job-ready in the AI domain with Capstone projects, practical labs, 
                  live sessions, and hands-on projects. 
                  Get your custom-made t-shirt when you complete a course!
                </p>
              </div>
            </Col>

            {/* <Col lg="2">
              <div className="hero__img-box">
                <img src={heroImg} alt="" />
              </div>
            </Col> */}
            <Col lg="6">
              <div className="hero__img-box hero__video-box mt-4">
                <video src={heroVideo} alt="" controls />
              </div>
            </Col>
            {/* <Col lg="2">
              <div className="hero__img-box mt-5">
                <img src={heroImg02} alt="" />
              </div>
            </Col> */}

            <SearchBar />
          </Row>
        </Container>
      </section>
      {/* ========== hero section start =========== */}
{/* ============ experience section start ============= */}
<section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"} />

                <h2>
                Use ai to create your custom-made t-shirt and receive it <br /> after completing one course
                </h2>
                <p>
                Virtual instructor-led AI courses such as Microsoft & Google certifications, 
                <br />
                delivered by seasoned instructors, for 6 hours & 9 hours weekly for Beginner & advanced courses respectively.
                </p>
              </div>

              <div className="counter__wrapper d-flex align-items-center gap-5 ">
                <div className="counter__box">
                  <span>10+</span>
                  <h6>Certification Courses</h6>
                </div>
                <div className="counter__box">
                  <span>50%</span>
                  <h6>More affordable</h6>
                </div>
                <div className="counter__box">
                  <span>5+</span>
                  <h6>Years experience</h6>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="experience__img">
                <img src={experienceImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ============ experience section end ============= */}
      {/* ============ featured tour section start ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"5:30pm Weekdays & 9am Saturdays"} />
              <h2 className="featured__tour-title">Join 3-hour Instructor-led Sessions</h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>
      {/* ============ featured tour section end ============ */}

      {/* ============ course content section start ============ */}
      <section>
        <Container>
            <Subtitle subtitle={"Course Content Overview"} />
            <Row>
            <Col lg="6">
            <main>
            <h2 className="featured__tour-title">Azure AI Certification</h2>
              <article className='info'>
                {mquestions.map((mquestion) => {
                  return (
                    <SingleQuestion key={mquestion.id} {...mquestion}></SingleQuestion>
                    );
                  })}
              </article>
            </main>
            </Col>
          </Row>
            <hr/>
          <Row>
            <Col lg="6">
            <main>
            <h2 className="featured__tour-title">Gen AI Certification</h2>
              <article className='info'>
                {questions.map((question) => {
                  return (
                    <SingleQuestion key={question.id} {...question}></SingleQuestion>
                    );
                  })}
              </article>
            </main>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col lg="6">
            <main>
            <h2 className="featured__tour-title">HTML/CSS/Python</h2>
              <article className='info'>
                {pquestions.map((pquestion) => {
                  return (
                    <SingleQuestion key={pquestion.id} {...pquestion}></SingleQuestion>
                    );
                  })}
              </article>
            </main>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ============ course content section end ============ */}

      {/* ============ testimonial section start ========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Testimonials"} />
              <h2 className="testimonial__title">What learners say about us</h2>
            </Col>
            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
      {/* ============ testimonial section end ========== */}
      <Newsletter />
    </>
  );
};

export default Home;
