import React from "react";
import "./newsletter.css";

import { Container, Row, Col } from "reactstrap";
import maleTourist from "../assets/images/male-tourist.png";

const Newsletter = () => {
  return (
  <section className="newsletter">
      <Container>
      <Row>
        <Col lg="6">

            <div className="newsletter__content">
              <h2>Subscribe now to get useful artificial intelligence updates.</h2>

              {/* <div className="newsletter__input">

                <input type="email" placeholder="Enter your email" />
                <button className="btn newsletter__btn">Subscribe</button>
              </div> */}

              <p>
                Get the latest news and updates about all
                things related to artificial intelligence.
              </p>
            </div>
            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
              <style type="text/css">
                {`
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 600px;
          }
          /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
             We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
        `}
              </style>
              <div id="mc_embed_signup">
                <form action="https://bootcampmatch.us21.list-manage.com/subscribe/post?u=11454165d81a45f2cf25e4905&amp;id=81babf698f&amp;f_id=00b5dfe6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
                  <div id="mc_embed_signup_scroll">
                    {/* <h2>Subscribe</h2> */}
                    <div class="indicates-required"><span class="asterisk"></span></div>
                    {/* <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="" /></div> */}
                    <div hidden=""><input type="hidden" name="tags" value="2861147,2861148" /></div>
                    <div id="mce-responses" class="clear foot">
                      <div class="response" id="mce-error-response" style={{display: 'none'}}></div>
                      <div class="response" id="mce-success-response" style={{display: 'none'}}></div>
                    </div>
                    <div aria-hidden="true" style={{position: "absolute", left: '-5000px'}}>
              
                      <input type="text" name="b_11454165d81a45f2cf25e4905_81babf698f" tabindex="-1" value="" />
                    </div>
                    <div class="optionalParent">
                      <div class="clear foot">
                        <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe" className="btn newsletter__btn" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}}/>
                        <p style={{margin: '0px auto'}}><a href="http://eepurl.com/iFbPxM" title="Mailchimp - email marketing made easy and fun"><span style={{display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px'}}>
                          {/* <img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}}/> */}
                          </span></a></p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

        </Col>
          <Col lg="6">
            <div className="newsletter__img">
              <img src={maleTourist} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Newsletter;
