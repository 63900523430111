import "./App.css";
import Layout from "./components/Layout/Layout";
import ReactGa from 'react-ga'

const tracking_Id = "UA-116858567-2";

ReactGa.initialize(tracking_Id)

function App() {
  return <Layout />;
}

export default App;
