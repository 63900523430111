const questions = [
    {
      id: 1,
      title: 'What days are the instructor-led sessions and for how long?',
      info:
        'All live sessions are 3 hours long twice on week days and Saturdays for both PST or EST',
    },
    {
      id: 2,
      title: 'What is the schedule for Microsoft AI courses?',
      info:
        'For both PST/EST timezones, Azure AI courses are 8:30pm on Tues/Thurs and 9am Saturdays.',
    },
    {
      id: 3,
      title: 'What is the schedule for Google Generative AI courses?',
      info:
        'For both PST/EST timezones, Generative AI courses are 5:30pm on Tues/Thurs and 12pm Saturdays.',
    },
    {
      id: 4,
      title: "What is the schedule for HTML/CSS/Python courses?",
      info:
        'For both PST/EST timezones, HTML/CSS/Python courses are 5:30pm on Mon/Weds/Fri.',
    },
    {
      id: 5,
      title: "What types of jobs can you get with these courses?",
      info:
        'You can apply for Google Gen AI Developer, Azure AI Engineer and Gen AI Specialist jobs.',
    },
    {
      id: 6,
      title: 'How do I earn the certificates?',
      info:
        'Minimum passing mark for each course is 85% of assessments, attendance + project completion.',
    },
    {
      id: 7,
      title: "For which courses will I be granted a Microsoft and Google Certification?",
      info:
        'Microsoft AI Course and Azure AI Exam + Gen AI Course and Google Gen AI Exams.',
    },
    {
      id: 8,
      title: "Can I get a refund if I need to discontinue my enrollment?",
      info:
        "You can cancel your ongoing enrollment within your first 7 days & we'll reimburse the course money.",
    },
    {
      id: 9,
      title: 'What are the prerequisites?',
      info:
        'There are no prerequisites for enrolling. Basic technical knowledge are enough to get started.',
    },
    {
      id: 10,
      title: 'What potential income can one expect to earn with a Gen AI certificate?',
      info:
        'Currently, in the US, the salary range is bewteen $150,000 to 230,000 annually.',
    },
    {
      id: 11,
      title: 'Can I know more about the chatGPT & LLM bootcamp program?',
      info:
        'It is the most project-packed course, where you build over 30 apps. Basic python programming is required.',
    },
    {
      id: 12,
      title: 'What is the tuition fee for the courses in Generative Artificial Intelligence?',
      info:
        'The full tuition fee, inclusive of taxes, start from $315 per month for a beginner to advanced program.',
    },
    {
      id: 13,
      title: 'How to pay for the Instructor-led Gen AI program?',
      info:
        'You conveniently pay for courses in installments. E.g 3-month zero to advanced Azure AI engineer program.',
    },
    {
      id: 14,
      title: 'What are the core skills you develop after completing the Generative ai courses?',
      info:
        'Understanding of generative models, TensorFlow, PyTorch, Azure AI services, Google Gen AI Studio, LLM apps, etc.',
    },
  ]
  export default questions
  