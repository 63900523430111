const pquestions = [
    {
        "id": 1,
        "title": "Introduction to HTML",
        "info": "HTML Basics, Document Structure, HTML Elements, HTML Attributes, HTML Headings, HTML Paragraphs, HTML Lists, HTML Links, HTML Images, HTML Forms"
      },
      {
        "id": 2,
        "title": "Introduction to CSS",
        "info": "CSS Basics, CSS Selectors, CSS Box Model, CSS Display, CSS Colors and Backgrounds, CSS Typography"
      },
    {
        "id": 3,
        "title": "CSS Layout",
        "info": "Box Sizing, CSS Positioning, CSS Floats, CSS Grid Layout, CSS Flexbox Layout, Responsive Design Principles"
      },
    {
        "id": 4,
        "title": "Advanced HTML and Multimedia",
        "info": "HTML Tables, HTML Semantics, HTML Multimedia (Audio and Video), HTML Canvas, HTML SVG, HTML Embedded Content, HTML Entities, HTML Metadata, Build An HTML/CSS Website Labs"
      },
    
     {
        "id": 5,
        "title": "Advanced CSS and Responsive Design",
        "info": "CSS Transformations, Responsive Web Design, Media Queries, CSS Frameworks (e.g., Bootstrap), CSS Best Practices"
      },
      
      {
        "id": 6,
        "title": "HTML5 and CSS3 Assorted Features",
        "info": "HTML5 Semantic Elements, HTML5 Forms, HTML5 Audio and Video, CSS3 Transitions and Animations, CSS3 Flexbox Enhancements, Finish Your HTML/CSS Website Labs"
      },
    {
        "id": 7,
        "title": "Python Basics",
        "info": "Types, Expressions and Variables, String Operations, Labs 0.0.0 Your first program"
      },
      {
        "id": 8,
        "title": "Python Containers",
        "info": "Lists and Tuples, Sets, Dictionaries, Labs 1.0.0"
      },
      {
        "id": 9,
        "title": "Python Programming Fundamentals",
        "info": "Conditions and Branching, Loops, Functions, Labs 1.0.1"
      },
      {
        "id": 10,
        "title": "More Python Functions",
        "info": "Objects and Classes, More In-depth Functions, Recursion, Recursion Labs 1.0.2"
      },
      {
        "id": 11,
        "title": "Python Projects",
        "info": "Code Along, Labs 1.0.3, Code Along 2, Project Assignment"
      },
       {
        "id": 12,
        "title": "Python Projects 2",
        "info": "Code Along 3, Labs 1.0.3, Code Along 4, Complete Project Assignment"
      }    
]

export default pquestions